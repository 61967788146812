.text-nowrap {
    white-space: nowrap !important;
}

h4 {
    font-size: 22px;
    font-weight: 700;
    line-height: 28px;
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(-40deg, #a8740c 0%, #aaafaa 100%);
    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.custom-gradient {
    background: linear-gradient(-40deg, #a8740c 0%, #484b48 100%);
    -webkit-text-fill-color: transparent;
}


h5 {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
}

h2 {
    font-size: 52px;
    font-weight: 700;
    line-height: 57px;
    letter-spacing: -2px;
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(-40deg, #db9b1e 0%, #C0D4C9 100%);
    background-clip: border-box;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

p {
    font-size: 14px;
}

.p-footer {
    background: linear-gradient(rgba(183, 206, 166, 1), rgba(236, 251, 254, 1), rgba(252, 254, 245, 1), rgba(192, 212, 201, 1));
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}

.footer-div {
    background: radial-gradient(53.25% 96.6% at 53.86% 100.69%, #69612993 0.82%, #5a4d12a6 30.73%, #14141473 75.58%, rgba(50, 51, 51, 0) 100%);
}

.gradient-icon {
    background: linear-gradient(180deg, #2589AC 0%, #DB9B1E 100%);
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

@keyframes moveUpDown {
    0% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(10px);
        /* Adjust the distance as needed */
    }
}

.animate-moveUpDown {
    animation: moveUpDown 1.2s infinite alternate cubic-bezier(0.25, 0.1, 0.25, 0.2);
}

.bg-clip-text {
    -webkit-background-clip: text;
    background-clip: text;
}

.bgcontact {
    background: linear-gradient(180deg, #2589AC 0%, #DB9B1E 100%);
}
