.btn-hover1:hover {
    color: #ffffffff;
}

.btn-hover1::before {
    border-radius: 50px 50px 50px 50px;
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    transition: all 0.3s ease;
    background: linear-gradient(
        160deg,
        rgb(22, 22, 22) 0%,
        rgba(219, 155, 30, 1) 100%
    );
    z-index: -1;
    top: 0;
    left: 0;
}

.btn-hover1:hover::before {
    width: 100%;
    background: linear-gradient(
        70deg,
        rgba(15, 23, 26) 0%,
        rgba(219, 155, 30, 1) 100%
    );
}
