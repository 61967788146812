.vertical-timeline-custom-line .vertical-timeline-element-icon {
    box-shadow: 0 0 0 0px #EEA913, inset 0 1px 0 rgba(196, 6, 6, 0.08),
        0 1px 0 1px rgba(99, 80, 80, 0.05);

}

@media only screen and (min-width: 1170px) {
    .vertical-timeline--two-columns .vertical-timeline-element-icon {
        width: 45px;
        height: 45px;
        left: 50%;
        top: 5%;
        margin-left: -22px;
    }

    .vertical-timeline--two-columns .vertical-timeline-element-content {
        margin-left: 0;
        padding: 1.5em;
        width: 44%;
    }
}

.vertical-timeline-element-icon svg {
    display: block;
    width: 18px;
    height: 18px;
    position: relative;
    left: 57%;
    top: 57%;
    margin-left: -12px;
    margin-top: -12px;
}

.vertical-timeline-element-content {
    position: relative;
    margin-left: 60px;
    background: #fff;
    border-radius: .25em;
    padding: 1em;
    box-shadow: 0 3px 0 #fff;
}

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
    background: var(--vertical-timeline-line-gradient, linear-gradient(to bottom, #b31c2b 0%, #EEA913 0%));
}


/* .vertical-timeline::before {
  background: linear-gradient(to bottom, red 1%, #EEA913 0%);
} */
